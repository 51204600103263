import React from 'react'
import { Box, Theme, useMediaQuery } from '@mui/material'
import theme from '@config/theme'
import Typography from '@mui/material/Typography'
import { YouTubeEvent } from 'react-youtube'

import AccordionVideo from '@components/core/container/Accordion/AccordionVideo'
import YoutubePlayer from '../../modules/Video'
import useAccordionControls from '@components/site/hooks/useAccordionControls'
import { kEventActionPlayVideoFAQ } from '@utils/constants'

export type FaqVideoProps = {
  faqVideo: MCDC.Props.IGlobalFaqVideo[]
  fullWidth?: boolean
  setActiveVideo?: (newActiveVideo: YouTubeEvent) => void
}

export default function FaqVideo({
  faqVideo,
  fullWidth,
  setActiveVideo,
}: FaqVideoProps) {
  const [activeAccordionId, handleAccordionChange] = useAccordionControls(0)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <>
      {faqVideo.map(
        (faq, index) =>
          (faq.video.youtubeId || faq.answer) && (
            <AccordionVideo
              key={index}
              title={faq.question}
              expanded={activeAccordionId === index}
              onChange={(event, isExpanded) =>
                handleAccordionChange(index, isExpanded)
              }
              sx={{
                backgroundColor: theme.palette.grey[100],
              }}
            >
              <Box
                sx={{
                  display: fullWidth && !isMobile ? 'flex' : 'block',
                  flexDirection: fullWidth && !isMobile ? 'row' : 'column',
                  justifyContent:
                    fullWidth && !isMobile ? 'space-between' : 'center',
                }}
              >
                {faq.video.youtubeId && (
                  <YoutubePlayer
                    image={faq.video.image}
                    headline={''}
                    key={index}
                    youtubeId={faq.video.youtubeId}
                    customStyle
                    onPlaying={({ target }: YouTubeEvent) => {
                      if (target != undefined && setActiveVideo != undefined) {
                        setActiveVideo(target as unknown as YouTubeEvent)
                      }
                    }}
                    trackingType={kEventActionPlayVideoFAQ}
                    fixedPlayerHeight="440px"
                    sx={{
                      margin: theme.spacing(-4, -4, 'auto', -4),
                      marginBottom: theme.spacing(4),
                      width: fullWidth && !isMobile ? '50%' : 'auto',
                      '> div': {
                        [theme.breakpoints.up('md')]: {
                          paddingTop: 'calc(4 / 3 * 100%)',
                          marginBottom: fullWidth && theme.spacing(-8),
                        },
                      },
                    }}
                  />
                )}
                {faq.answer && (
                  <Typography
                    sx={{
                      width: fullWidth && !isMobile ? '50%' : '100%',
                    }}
                  >
                    {faq.answer}
                  </Typography>
                )}
              </Box>
            </AccordionVideo>
          )
      )}
    </>
  )
}
